import { RequestInfoDTO } from 'pmrv-api';

export const AVIATION_REQUEST_TYPES: RequestInfoDTO['type'][] = [
  'AVIATION_ACCOUNT_CLOSURE',
  'AVIATION_NON_COMPLIANCE',
  'EMP_BATCH_REISSUE',
  'EMP_REISSUE',
  'AVIATION_VIR',
  'EMP_ISSUANCE_UKETS',
  'EMP_VARIATION_UKETS',
  'AVIATION_AER_UKETS',
  'AVIATION_DRE_UKETS',
  'EMP_ISSUANCE_CORSIA',
  'EMP_VARIATION_CORSIA',
  'AVIATION_AER_CORSIA',
];
